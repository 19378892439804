import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { RouteName } from '@/enums';
import { useAuthStore } from '@/stores';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();

  if (to.meta.requireAuth && !auth.isAuthenticated) {
    next({ name: RouteName.Login });
  } else if ((to.meta.hideForAuth && auth.isAuthenticated) || to.meta.toHome) {
    next({ name: RouteName.Home });
  } else next();
});

router.onError((error, to) => {
  const errors = [
    'Failed to fetch dynamically imported module',
    'Unable to preload CSS',
  ];

  if (errors.some((e) => error.message.includes(e))) {
    return (window.location.pathname = to.fullPath);
  }

  console.error(error);
});

export default router;
